.home {
  &__soumission {
    width: 100%;
  }

  &__image {
    width: 100%;
    max-height: 700px;
    margin-top: 200px;
  }

  &__soumission {
    margin-top: -200px;
  }
}
