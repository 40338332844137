.contact {
  color: #242318;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__title {
    font-family: Eurostile Bold;
    font-size: 2.8125rem;
    text-transform: uppercase;
    padding-bottom: 1.25rem;
  }

  &__text {
    font-family: Eurostile Medium;
    font-size: 1.5625rem;
    line-height: 2rem;
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__members {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    min-width: 40%;
  }

  &__member {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    max-width: 131px;
    justify-self: center;
    align-self: center;
  }
  &__license {
    font-family: Eurostile Bold;
    font-size: 25px;
  }

  &__bottom-right {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
  }
}

@media (min-width: 1024px) {
  .contact {
    padding: 3.2875rem 5.3125rem;
    &__title {
      font-size: 3.125rem;
      padding-bottom: 1.75rem;
    }

    &__members {
      gap: 1.75rem;
    }
    &__text {
      font-size: 25px;
      line-height: 2.4rem;
    }
    &__license {
      font-family: Eurostile Bold;
      font-size: 25px;
    }
  }
}
@media (min-width: 1300px) {
  .contact {
    justify-content: space-between;

    &__content {
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
    }

    &__bottom-right {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      gap: 20px;
      align-items: flex-start;
      text-align: start;
      justify-content: flex-start;
    }
    &__bottom-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: start;
    }

    &__title {
      margin-bottom: 0;
      width: 100%;
      font-family: Eurostile Bold;
      font-size: 45px;
      text-transform: uppercase;
    }

    &__row {
      flex-direction: row;
      gap: 0;
    }

    &__text {
      font-family: Eurostile Medium;
      font-size: 25px;
    }

    &__license {
      padding-bottom: 1.75rem;
      font-size: 45px;
      font-family: Eurostile Bold;
    }

    &__logo {
      align-self: flex-start;
    }
  }
}
