.temoignages {
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;

  &__container {
    position: relative;
    display: flex;
    width: 100%;
    transition: transform 0.5s ease-in-out;
    &.animating {
      transition: transform 0.5s ease-in-out;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    scroll-snap-align: start;
    padding: 20px 40px;
    max-height: 500px;
    background-color: #f7f4f0; /* Couleur de fond du rectangle */
    border-radius: 10px; /* Pour des coins arrondis, si souhaité */
  }

  &__text {
    text-align: left;
    width: 100%;
    font-family: "Eurostile Medium", sans-serif;
    position: relative;
    color: #242318;
    font-size: 17px;
    margin-bottom: 20px;
    line-height: 1.5rem;

    @media (min-width: 576px) {
      font-size: 25px;
    }
  }

  &__name {
    text-align: right;
    width: 100%;
    font-family: "Eurostile Medium", sans-serif;
    position: relative;
    color: #242318;
    font-size: 15px;
    @media (min-width: 576px) {
      font-size: 25px;
    }
  }

  &__text-overlay {
    font-family: "Eurostile Bold", sans-serif;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    padding-top: 40px;
    margin-bottom: 30px;
    font-size: 45px;

    color: #242318;
    z-index: 10;
    @media (min-width: 576px) {
      font-size: 50px;
    }
  }

  &__dots {
    height: auto;
    width: 100%;
    text-align: center;
    z-index: 5;
  }

  &__dot {
    display: inline-block;
    width: 38px;
    height: 7px;
    margin: 0 5px;
    background-color: #bab2aa;
    opacity: 50%;
    border-radius: 0;
    cursor: pointer;
    transition: background-color 0.3s;

    &.active {
      background-color: #bab2aa;
      opacity: 100%;
    }
  }
}
@media (min-width: 768px) {
  .temoignages {
    &__text {
      padding: 0 3.5rem;
      line-height: 2.2rem;
    }

    &__name {
      padding: 0 3.5rem;
    }
  }
}

@media (min-width: 1024px) {
  .temoignages {
    &__text-overlay {
      padding-top: 50px;
      font-size: 4.5rem;
    }
    &__text {
      font-size: 1.4875rem;
    }
  }
}
@media (min-width: 1400px) {
  .temoignages {
    &__text {
      padding: 0 11rem;
    }

    &__name {
      padding: 0 11rem;
    }
  }
}
