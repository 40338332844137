.project-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;

  &__main {
    width: 100%;
  }

  &__secondary {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    overflow: hidden;
  }

  &__img {
    width: 100%;
    height: auto;
    display: block;
    flex-shrink: 0;
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.column {
  flex-direction: column;
}

@media (min-width: 576px) {
  .project-card {
    flex-direction: row;
    width: 100%;
    gap: 30px;

    &__main {
      width: 100%;
    }
    &__secondary {
      flex-direction: column;
      width: 60%;
      height: 100%;
      gap: 30px;
    }
    &__img {
      width: 100%;
    }
  }

  .column {
    flex-direction: row;
  }

  .column-reverse {
    flex-direction: row-reverse;
  }
}
