.members {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem; /* 20px */
  padding-top: 3.5rem; /* 60px */
  overflow-x: hidden;
  justify-content: space-between;

  &__text {
    padding: 0 1.875rem; /* 30px */
    text-align: center;
    font-family: Eurostile Medium;
    font-size: 1.165rem; /* 22px */
    line-height: 1.8rem;
  }
}

@media (min-width: 420px) {
  .members {
    &__text {
      font-size: 1.375rem; /* 35px */
    }
  }
}

@media (min-width: 576px) {
  .members {
    gap: 1.25rem; /* 20px */
    &__text {
      padding: 2rem 6.6875rem;
      font-size: 2.1875rem; /* 35px */
      line-height: 2.5rem;
    }
  }
}
@media (min-width: 1024px) {
  .members {
    gap: 1.25rem; /* 20px */
    &__text {
      padding: 2rem 6.6875rem;
      font-size: 1.8rem; /* 35px */
      line-height: 2.5rem;
    }
  }
}

@media (min-width: 1200px) {
  .members {
    gap: 1.25rem; /* 20px */
    &__text {
      padding: 2rem 14.6875rem;
      font-size: 1.6rem; /* 35px */
      line-height: 2.5rem;
    }
  }
}
