.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.375rem;

  &__img {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
  &__title {
    max-width: 95%;
    font-family: Eurostile Bold;
    font-size: 2.5rem;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1),
      3px 3px 8px rgba(0, 0, 0, 0.1);
  }

  &__text {
    max-width: 95%;
    font-family: Eurostile Medium;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media (min-width: 400px) {
  .card {
    &__title {
      font-size: 3rem;
    }
  }
}

@media (min-width: 576px) {
  .card {
    flex-direction: row;
    gap: 1.25rem;
    max-width: 100%;
    &__content {
      display: flex;
      flex-direction: column;
      gap: 1.5625rem;
    }

    &__img {
      max-width: 50%;
      height: auto; /* Pour maintenir le rapport d'aspect */
    }
    &__text {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }
}

@media (min-width: 1024px) {
  .card {
    gap: 3rem;

    &__title {
      font-size: 3.5rem;
    }

    &__content {
      max-width: 50%;
    }
    &__text {
      font-size: 1.3375rem;
    }
  }
}
@media (min-width: 1200px) {
  .card {
    gap: 3.5rem;

    &__title {
      width: 90%;
      font-size: 4.2875rem;
    }

    &__content {
      max-width: 50%;
    }
    &__text {
      font-size: 1.4rem;
    }
  }
}
