.mobile-menu {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #242318;
  transition: transform 0.8s ease-in-out;
  transform: translatex(130%);
  z-index: 300;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;

  &__text {
    margin: 0;
    font-family: Eurostile Medium;

    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2.1875rem;
    font-size: 2.1875rem;
    text-decoration: none;
    &::before {
      content: "";
      position: absolute;
      bottom: 0; // Position the underline at the bottom of the link
      left: -5%;
      width: 110%;
      height: 2px; // Adjust the thickness of the underline
      background-color: currentColor; // Use the current text color
      transform: scaleX(0);
      transform-origin: bottom left;
      transition: transform 0.4s ease-in-out;
    }

    &:hover::before {
      transform: scaleX(1);
    }
  }

  &__link {
    color: #f7f4f0;
    position: relative;
    display: inline-block;
    text-decoration: none; /* Ensure no underline on links */
  }

  &__socials {
    padding-top: 40px;
    color: #f7f4f0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  &__social {
    display: flex;

    svg {
      color: #f7f4f0;
      font-size: 2.1875rem;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &--open {
    transform: translateY(0);
  }
}
@media screen and (min-width: 768px) {
  .mobile-menu {
    &__text {
      font-size: 3.5rem;
    }
  }
}
