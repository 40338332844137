.alert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #242318;
  color: white;
  text-align: center;
  padding: 1.125rem 2.5rem;
  z-index: 1000;
  transition: transform 0.8s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(100%); /* L'alerte commence hors de l'écran */

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.625rem;
  &__text {
    font-family: Eurostile Bold;
    font-size: 0.935rem;
    text-transform: uppercase;
  }
  svg {
    font-size: 0.935rem;
  }
  &.active {
    opacity: 1;

    transform: translateY(0); /* L'alerte remonte à sa position normale */
  }

  &:hover {
    cursor: pointer;
    transform: translateY(0) scale(1.1); /* Effet de grossissement lors du hover */
  }

  &--extra-padding {
    padding-bottom: 50px;
  }
}

@media (min-width: 576px) {
  .alert {
    &__text {
      font-family: Eurostile Bold;
      font-size: 1.2875rem;
      text-transform: uppercase;
    }
    svg {
      font-size: 1.2875rem;
    }
    gap: 1.5rem;
  }
}
