.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100vw;
  background-color: transparent;
  z-index: 9000;
  transition: background-color 0.5s ease-in-out, top 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
  overflow: hidden;

  box-sizing: border-box;

  &__content {
    display: flex;
    flex-direction: column;
    padding: 1.875rem;
    transition: ease-in 0.1s;
  }

  &__hamburger {
    display: block;
    z-index: 2000;
  }

  &__nav {
    display: none;
  }

  &__bar {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Utilisez width au lieu de max-width */
    max-width: 100%; /* Évite que le contenu dépasse */
    margin: 0 auto; /* Centrer le contenu */
    gap: 8px;
    z-index: 2000;
  }

  &__logo {
    min-width: 160px;
    height: auto;
    max-width: 200px;
    max-height: 50px;
    z-index: 1;
    transition: opacity 1.2s ease-in-out;
  }

  &__logo--hidden {
    opacity: 0;
  }

  &--open {
    position: fixed;
    height: 100%;
    background-color: #242318;
  }

  &--scrolled {
    background-color: #242318; /* Changez la couleur de fond selon vos besoins */
  }
  &--fixed {
    position: fixed;
    top: 0px;
  }

  &--transparent {
    background-color: transparent !important; /* Maintient le header transparent */
  }
}

@media (min-width: 768px) {
  .header {
    &__logo {
      max-width: 250px;
      max-height: 100px;
    }
    &__content {
      padding: 2.5rem;
    }
  }
}

@media (min-width: 1024px) {
  .header {
    &__content {
      height: fit-content;
      padding: 2.4rem 5.3125rem;
    }

    &__hamburger {
      display: none !important;
    }

    &__logo {
      width: 100%;
      height: 100%;
    }

    &__nav {
      display: none;
    }
  }
}
