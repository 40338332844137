.produit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;

  &__overlay {
    position: absolute;
    bottom: 1.875rem; /* Converti en rem */
    left: 1.875rem; /* Converti en rem */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  &__text {
    position: absolute;
    color: #f7f4f0;
    width: 100%;
    font-size: 2rem; /* Taille par défaut */
    text-align: start;
    font-family: Eurostile Bold;
    text-transform: uppercase;
    z-index: 20;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4), 2px 2px 4px rgba(0, 0, 0, 0.4),
      3px 3px 8px rgba(0, 0, 0, 0.4);
  }
}

// Media query
@media screen and (min-width: 400px) {
  .produit {
    &__text {
      font-size: 2.5rem; /* Ajustez la taille pour les écrans plus larges */
    }
  }
}
