.links {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3.75rem 1.875rem; /* 60px => 3.75rem, 30px => 1.875rem */

  &__title {
    text-align: center;
    font-family: Eurostile Bold;
    font-size: 2.8125rem; /* 45px => 2.8125rem */
    text-transform: uppercase;
    padding-bottom: 2.5rem; /* 40px => 2.5rem */
    border-bottom: 0.125rem solid #242318; /* 2px => 0.125rem */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1),
      3px 3px 8px rgba(0, 0, 0, 0.1);
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 576px) {
  .links {
    padding: 3.75rem 5.625rem 3.75rem 5.625rem; /* 40px => 2.5rem, 90px => 5.625rem, 60px => 3.75rem */

    &__title {
      font-size: 3.125rem; /* 50px => 3.125rem */
    }
  }
}

@media (min-width: 1200px) {
  .links {
    padding: 3.75rem 9.625rem 3.75rem 9.625rem; /* 40px => 2.5rem, 90px => 5.625rem, 60px => 3.75rem */

    &__title {
      font-size: 4.5rem;
    }
  }
}
