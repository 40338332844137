.main-image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  &__overlay {
    position: absolute;
    bottom: 1.25rem; /* 20px => 1.25rem */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__text {
    color: #f7f4f0;
    font-size: 1.125rem; /* 18px => 1.125rem */
    text-align: start;
    text-transform: uppercase;
    font-family: "Eurostile Bold";
    z-index: 20;
    position: absolute;
    left: 1.5625rem; /* 25px => 1.5625rem */
    cursor: pointer;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4), 2px 2px 4px rgba(0, 0, 0, 0.4),
      3px 3px 8px rgba(0, 0, 0, 0.4);
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: -5%;
      width: 110%;
      height: 0.125rem; /* 2px => 0.125rem */
      background-color: currentColor;
      transform: scaleX(0);
      transform-origin: bottom left;
      transition: transform 0.4s ease-in-out;
    }

    &:hover::before {
      transform: scaleX(1);
    }
  }
  &__text-home {
    color: #f7f4f0;
    font-size: 1.125rem; /* 18px => 1.125rem */
    text-align: start;
    text-transform: uppercase;
    font-family: "Eurostile Bold";
    z-index: 20;
    position: absolute;
    left: 1.5625rem; /* 25px => 1.5625rem */
    cursor: pointer;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4), 2px 2px 4px rgba(0, 0, 0, 0.4),
      3px 3px 8px rgba(0, 0, 0, 0.4);
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: -5%;
      width: 110%;
      height: 0.125rem; /* 2px => 0.125rem */
      background-color: currentColor;
      transform: scaleX(0);
      transform-origin: bottom left;
      transition: transform 0.4s ease-in-out;
    }

    &:hover::before {
      transform: scaleX(1);
    }
  }

  &__icon {
    font-size: 1.375rem; /* 22px => 1.375rem */
    color: #f7f4f0;
    position: absolute;
    right: 1.5625rem; /* 25px => 1.5625rem */
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5))
      drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5))
      drop-shadow(3px 3px 8px rgba(0, 0, 0, 0.5));
    &:hover {
      transform: scaleX(1.5);
      transform-origin: center;
    }
  }
}

@media (min-width: 420px) {
  .main-image {
    &__icon {
      font-size: 1.875rem; /* 30px => 1.875rem */
    }
  }
}

@media (min-width: 576px) {
  .main-image {
    &__text-home {
      font-size: 2.1875rem; /* 35px => 2.1875rem */
    }
    &__overlay {
      padding: 2rem;
    }
    &__text {
      font-size: 2.1875rem; /* 35px => 2.1875rem */
      max-width: 80%;
    }

    &__icon {
      font-size: 2.1875rem; /* 35px => 2.1875rem */
    }
  }
}

@media (min-width: 1024px) {
  .main-image {
    width: 100%;
    &__overlay {
      width: 100%;
    }

    &__text {
      font-size: 2.1875rem; /* 35px => 2.1875rem */
      left: 3.75rem;
    }

    &__icon {
      font-size: 2.1875rem; /* 35px => 2.1875rem */
      right: 3.75rem;
    }
  }
}

@media (min-width: 1200px) {
  .main-image {
    width: 100%;
    &__text-home {
      margin-left: 3.5rem;
    }
  }
}
