.projects {
  display: flex;
  padding: 35px 30px;
  flex-direction: column;
  gap: 20px;
}
@media (min-width: 576px) {
  .projects {
    padding: 40px;
    justify-content: space-between;
    gap: 30px;
  }
}
@media (min-width: 1024px) {
  .projects {
    padding: 4.25rem 4rem 4.25rem 4rem; /* Converti en rem */
    justify-content: space-between;
    gap: 1.875rem;
  }
}
