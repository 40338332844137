.soumission {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1.5625rem;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    background-color: #877851;
    border-radius: 0.9375rem;
    padding: 3.75rem 1.5625rem 3.75rem 1.5625rem;
    justify-content: center;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2),
      0 0.375rem 1.25rem rgba(0, 0, 0, 0.19); /* Ajoute une ombre portée */
    transform: perspective(62.5rem) translateZ(0); /* Ajoute une légère transformation 3D */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  &__container:hover {
    transform: perspective(62.5rem) translateZ(0.625rem); /* Augmente la transformation 3D au survol */
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2),
      0 0.75rem 2.5rem rgba(0, 0, 0, 0.19); /* Augmente l'ombre portée au survol */
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  }

  &__row {
    display: flex;
    flex-direction: row;
    gap: 0.9375rem;
    justify-content: space-between;
    width: 100%;
  }

  &__row--end {
    justify-content: flex-end;
  }

  &__title {
    color: #242318;
    text-align: center;
    max-width: 100%;
    font-family: Eurostile Bold;
    font-size: 2.4rem;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1),
      3px 3px 8px rgba(0, 0, 0, 0.1);
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(36, 35, 24, 0.2);
    border-radius: 0.75rem;
    padding: 1.5625rem 1.375rem;
    width: 100%;
  }

  &__input--nom {
    flex: 1 1 33.33%; /* Adjusted to take up 1/3 of the space */
  }

  &__input--numero {
    flex: 2 1 66.67%; /* Adjusted to take up 2/3 of the space */
  }

  &__file-input {
    display: none;
  }

  &__file-label {
    width: 100%;
    min-width: 1.875rem;
    font-size: 0.9375rem;
    color: #242318;
    font-family: Eurostile Bold;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &__icon {
    font-size: 1.25rem; /* Taille de l'icône */
  }

  input[type="text"],
  input[type="email"],
  input[type="file"] {
    width: 100%;
    font-size: 0.875rem;
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #242318;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: rgba(36, 35, 24, 0.5);
      font-size: 0.85rem;
      font-family: "Eurostile Bold";
      text-transform: uppercase;
      outline: none;
    }
  }
}
@media (min-width: 400px) {
  .soumission {
    padding: 0 2rem;

    &__text {
      font-size: 0.9375rem;
    }
  }
}
@media (min-width: 576px) {
  .soumission {
    padding: 0 6rem;
    &__title {
      padding: 0 4rem;
      font-size: 3.125rem;
    }

    input[type="text"],
    input[type="email"],
    input[type="file"] {
      font-size: 1.25rem;

      &::placeholder {
        font-size: 1.25rem;
      }
    }
  }
}

@media (min-width: 1024px) {
  .soumission {
    padding: 0 8rem;
    &__title {
      padding: 0 8rem;
      font-size: 3.125rem;
    }

    &__inputs {
      padding: 0 3.125rem;
      gap: 1.5rem;
    }

    &__row {
      gap: 1.5rem;
    }
    &__text {
      font-size: 0.9375rem;
    }

    input[type="text"],
    input[type="email"],
    input[type="file"] {
      font-size: 1.25rem;

      &::placeholder {
        font-size: 1.25rem;
      }
    }
  }
}
@media (min-width: 1300px) {
  .soumission {
    padding: 0 20.5rem;
    &__title {
      padding: 0 2rem;
      font-size: 3.6875rem;
    }
    &__text {
      font-size: 0.9375rem;
    }
  }
}
@media (min-width: 1600px) {
  .soumission {
    padding: 0 28.5rem;
    &__title {
      font-size: 4.6875rem;
    }
    &__text {
      font-size: 0.9375rem;
    }
  }
}
