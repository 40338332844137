.about {
  &__header {
    padding-bottom: 3.125rem;
  }
}
@media (min-width: 1024px) {
  .about {
    &__header {
      padding-bottom: 6.25rem;
    }
  }
}
