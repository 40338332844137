.infos {
  width: 100%;
  height: 100%;
  padding-bottom: 2.5rem;

  &__services {
    background-color: #877851;
    padding: 3.75rem 1.875rem 9.375rem 1.875rem;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
  }

  &__secteurs {
    display: flex;
    flex-direction: column;
    background-color: #f7f4f0;
  }

  &__produits {
    margin-top: -8.75rem;
    padding: 1.875rem 1.875rem 0 1.875rem;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
  }

  &__produit {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .infos {
    padding-bottom: 0;
    &__services {
      width: 100%;
      padding: 6.25rem 2.5rem 11.25rem 2.5rem;
      display: flex;
      flex-direction: column;
      gap: 5.625rem; /* Converti en rem */
      justify-content: space-between;
    }

    &__secteurs {
      display: flex;
      flex-direction: row;
      background-color: #f7f4f0;
    }

    &__produits {
      margin-top: -6.25rem; /* Converti en rem */
      padding: 1.875rem 1.25rem 3.125rem 1.25rem; /* Converti en rem */
      display: flex;
      flex-direction: row;
      gap: 1.25rem; /* Converti en rem */
    }
  }
}

@media (min-width: 1024px) {
  .infos {
    padding-bottom: 2.5rem;
    &__services {
      width: 100%;
      padding: 9.375rem 6.25rem 13.75rem 6.25rem; /* Converti en rem */
      display: flex;
      flex-direction: column;
      gap: 5.625rem; /* Converti en rem */
      justify-content: space-between;
    }

    &__secteurs {
      display: flex;
      flex-direction: row;
      background-color: #f7f4f0;
    }

    &__produits {
      margin-top: -6.25rem; /* Converti en rem */
      padding: 1.875rem 1.25rem 3.125rem 1.25rem; /* Converti en rem */
      display: flex;
      flex-direction: row;
      gap: 1.25rem; /* Converti en rem */
    }
  }
}
