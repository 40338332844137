.link {
  display: flex;
  flex-direction: column;
  color: #242318;
  border-bottom: 2px solid #242318;
  &__title {
    padding: 1.8rem 0.5rem;
    font-family: Eurostile Bold;
    font-size: 30px;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__icon {
    img {
      transition: 0.3s ease-in-out;
    }
  }

  &.active &__icon {
    img {
      transform: rotate(180deg);
    }
  }

  &__text {
    max-height: 0;
    opacity: 0;
    transform: translateY(-20px);
    font-family: Eurostile Medium;
    font-size: 18px;
    overflow: hidden;
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
    a {
      font-family: Eurostile Bold; /* Applique la police en gras */
      font-weight: bold; /* Rend le texte en gras */
      color: inherit; /* Hérite de la couleur du texte parent */
      text-decoration: none; /* Supprime la sous-ligne par défaut des liens */
    }
  }

  &.active &__text {
    opacity: 1;
    transform: translateY(0);
    max-height: 600px; // Une valeur haute pour garantir que tout le texte soit visible
    padding-bottom: 2rem;
    line-height: 1.8rem;
  }
}

@media (min-width: 576px) {
  .link {
    &__title {
      font-size: 25px;
    }

    &__text {
      font-size: 22px;
    }

    &.active &__text {
      line-height: 2.2rem;
    }
  }
}
