.navMenu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &__links {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: center;
    justify-content: flex-end;
    gap: 1.5625rem;
  }

  &__link {
    display: inline-block;
    justify-self: flex-end;
    opacity: 0.6;
    transition: opacity 0.3s ease; /* Add a smooth transition */

    &:hover {
      opacity: 1;
    }
  }

  .nav-link-active {
    opacity: 1;
  }

  &__text {
    position: relative;
    font-family: Eurostile Bold;
    font-size: 1.125rem;
    text-transform: uppercase;
    color: #f7f4f0; /* Default color */

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: -5%;
      width: 110%;
      height: 2px;
      background-color: currentColor;
      transform: scaleX(0);
      transform-origin: bottom left;
      transition: transform 0.4s ease-in-out;
    }

    &:hover::before {
      transform: scaleX(1);
    }
  }

  /* Modifier class with higher specificity */
  .navMenu__text--dark {
    color: #242318 !important; /* Dark color with !important to override */
  }
}

@media (min-width: 1200px) {
  .navMenu {
    &__links {
      gap: 2.5rem;
    }
    &__text {
      font-size: 1.4375rem;
    }
  }
}
