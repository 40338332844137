.slogan {
  max-width: 100%;
  width: auto;
  display: flex;
  padding: 3.125rem 1.875rem;
  flex-direction: column;
  gap: 3.4375rem;

  &__infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.625rem;
  }

  &__title {
    font-family: Eurostile Bold;
    font-size: 2.3rem;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1),
      3px 3px 8px rgba(0, 0, 0, 0.1);
  }

  &__secteurs {
    font-family: Eurostile Bold;
    font-size: 1.1875rem;
    text-transform: uppercase;
  }

  &__text {
    font-family: Eurostile Medium;
    font-size: 1rem;
    line-height: 1.4rem;
  }

  &__logo {
    display: flex;
    align-self: flex-end;
    width: 60%;
    opacity: 0; // Initialement invisible
    animation: fadeIn 2s forwards; // Ajoutez cette ligne pour l'animation
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media (max-width: 756px) {
  .slogan__logo img {
    margin-right: auto;
    width: 100%;
    max-width: 200px;
  }
}

@media (min-width: 756px) {
  .slogan {
    padding: 3.125rem 2.5rem;

    &__title {
      font-size: 4.6875rem;
    }
    &__infos {
      gap: 0.9375rem;
    }

    &__secteurs {
      font-size: 1.6875rem;
    }

    &__text {
      font-size: 1.4375rem;
      line-height: 2rem;
    }

    &__logo {
      max-width: 400px;
    }
  }
}

@media (min-width: 1024px) {
  .slogan {
    padding: 6.25rem;
    flex-direction: row;
    width: 100%;

    &__title {
      font-family: Eurostile Bold;
      font-size: 3.6875rem;
      text-transform: uppercase;
    }
    &__infos {
      max-width: 65%;
      gap: 0.9375rem;
    }
    &__logo {
      display: flex;
      width: 100%;
      align-self: flex-end;
      align-items: flex-end;
      justify-items: end;
      align-content: flex-end;
      max-width: 350px;
    }

    &__secteurs {
      font-size: 1.4875rem;
    }

    &__text {
      font-size: 1.3375rem;
    }
  }
}
@media (min-width: 1200px) {
  .slogan {
    padding: 6.25rem;
    flex-direction: row;
    width: 100%;

    &__title {
      font-family: Eurostile Bold;
      font-size: 4.2875rem;
      text-transform: uppercase;
    }
    &__infos {
      max-width: 65%;
      gap: 0.9375rem;
    }
    &__logo {
      display: flex;
      width: 100%;
      align-self: flex-end;
      align-items: flex-end;
      justify-items: end;
      align-content: flex-end;
      max-width: 350px;
    }

    &__secteurs {
      font-size: 1.4875rem;
    }

    &__text {
      font-size: 1.2375rem;
    }
  }
}
