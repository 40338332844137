.member {
  display: flex;
  flex-direction: column;
  gap: 2.1875rem; /* 35px */
  padding: 1.875rem; /* 30px */
  justify-content: space-between;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.25rem; /* 20px */
  }

  &__img {
    width: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Ajout des transitions pour un effet de profondeur fluide */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4); /* Ajout d'une ombre légère pour la profondeur initiale */
  }

  &__name {
    width: 50%;
    text-align: left;
    font-family: Eurostile Bold;
    font-size: 3.4375rem; /* 55px */
    text-transform: uppercase;
  }

  &__text {
    width: 100%;
    text-align: left;
    font-family: Eurostile Medium;
    font-size: 0.9375rem; /* 15px */
    line-height: 1.5rem;
  }

  &--background-green {
    padding: 3.75rem 1.875rem 2.5rem 1.875rem; /* 60px 30px 40px 30px */
    background-color: #877851;
  }
}

@media (min-width: 576px) {
  .member {
    padding: 2.5rem; /* 40px */
    gap: 1.645rem; /* 20px */

    &__name {
      font-size: 5rem; /* 80px */
    }

    &__text {
      font-size: 1.675rem; /* 30px */
    }
  }
}

@media (min-width: 1024px) {
  .member {
    padding: 4.75rem 4.6875rem; /* 40px */
    gap: 1.645rem; /* 20px */

    &__content {
      align-self: center;
      width: 80%;
    }
    &__name {
      width: 100%;
      padding: 1rem 0 0 0;
      text-align: left;
      font-size: 4.2rem; /* 80px */
      align-self: left;
    }

    &__text {
      padding: 1rem 4.75;
      font-size: 1.3375rem; /* 30px */
      line-height: 2.2rem;
    }

    &__img {
      width: 80%;
      height: fit-content;
      align-self: center;
    }

    &--background-green {
      padding: 4.75rem 4.6875rem; /* 60px 30px 40px 30px */
      background-color: #877851;
    }
  }
}
@media (min-width: 1200px) {
  .member {
    &__img {
      width: 60%;
      height: fit-content;
      align-self: center;
    }
    &__name {
      width: 100%;
      padding: 1rem 0 0 0;
      text-align: center;
      font-size: 4.5rem; /* 80px */
      align-self: left;
    }
  }
}
