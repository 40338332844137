.hero {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    font-family: Eurostile Bold;
    color: white;
    width: 100%;
    padding: 1rem 2.425rem; /* 50px = 3.125rem */
    font-size: 2.825rem; /* 58px = 3.625rem */
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4), 2px 2px 4px rgba(0, 0, 0, 0.4),
      3px 3px 8px rgba(0, 0, 0, 0.4);
  }

  &__chatWidget {
    position: absolute;
    bottom: 1.875rem; /* 30px = 1.875rem */
    right: 1.25rem; /* 20px = 1.25rem */
    overflow: hidden;
    z-index: 40000;
  }
}

@media (min-width: 400px) {
  .hero {
    &__text {
      font-size: 3rem; /* 57px => 3.5625rem */
    }
  }
}
@media (min-width: 576px) {
  .hero {
    &__overlay {
      top: 10%;
      bottom: 0;
    }
    &__text {
      font-size: 4.635rem; /* 57px => 3.5625rem */
      padding: 3rem 7.5rem; /* 12rem is the same as 192px */
    }
  }
}
@media (min-width: 1024px) {
  .hero {
    &__overlay {
      top: 10%;
      bottom: 0;
    }
    &__text {
      font-size: 4.5rem; /* 57px => 3.5625rem */
      padding: 3rem 15rem; /* 12rem is the same as 192px */
    }
  }
}
@media (min-width: 1200px) {
  .hero {
    &__overlay {
      top: 10%;
      bottom: 0;
    }
    &__text {
      font-size: 5.235rem; /* 57px => 3.5625rem */
      padding: 3rem 20rem; /* 12rem is the same as 192px */
    }
  }
}
